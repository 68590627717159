export class TapeMeasure {
  constructor() {
    // GeoJSON object to hold our measurement features
    this.geojson = {
      type: "FeatureCollection",
      features: [],
    };
    this.domElementId = "";
    this.map = {};
    this.turf = null;
    this.activated = null;
  }

  initDistanceMeasurer(map, domElementId, activated, turf) {
    this.domElementId = domElementId;
    this.map = map;
    this.turf = turf;
    this.activated = activated;
  }

  addDistanceMeasurer() {
    const distanceContainer = document.getElementById(this.domElementId);

    // Used to draw a line between points
    const linestring = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [],
      },
    };

    this.addSourceAndLayers();

    this.map.on("click", (e) => {
      if (!this.activated()) return;
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ["measure-points"],
      });

      // Remove the linestring from the group
      // so we can redraw it based on the points collection.
      if (this.geojson.features.length > 1) this.geojson.features.pop();

      // Clear the distance container to populate it with a new value.
      distanceContainer.innerHTML = "";

      // If a feature was clicked, remove it from the map.
      if (features.length) {
        const id = features[0].properties.id;
        this.geojson.features = this.geojson.features.filter(
          (point) => point.properties.id !== id
        );
      } else {
        const point = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [e.lngLat.lng, e.lngLat.lat],
          },
          properties: {
            id: String(new Date().getTime()),
          },
        };
        if (this.geojson.features.length > 1) {
          this.geojson.features = [];
        }
        this.geojson.features.push(point);
      }

      if (this.geojson.features.length > 1) {
        linestring.geometry.coordinates = this.geojson.features.map(
          (point) => point.geometry.coordinates
        );

        this.geojson.features.push(linestring);

        // Populate the distanceContainer with total distance
        const value = document.createElement("div");
        const distance = this.turf.length(linestring);
        value.textContent = `Distance: ${distance.toLocaleString()}km`;
        //value.style.background = "white";
        distanceContainer.appendChild(value);
      }

      this.map.getSource("geojson").setData(this.geojson);
    });

    this.map.on("mousemove", (e) => {
      if (!this.activated()) return;
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ["measure-points"],
      });
      // Change the cursor to a pointer when hovering over a point on the map.
      // Otherwise cursor is a crosshair.
      this.map.getCanvas().style.cursor = features.length
        ? "pointer"
        : "crosshair";
    });
  }
  addSourceAndLayers() {
    this.map.addSource("geojson", {
      type: "geojson",
      data: this.geojson,
    });

    // Add styles to the map
    this.map.addLayer({
      id: "measure-points",
      type: "circle",
      source: "geojson",
      paint: {
        "circle-radius": 8,
        "circle-color": "#000",
      },
      filter: ["in", "$type", "Point"],
    });
    this.map.addLayer({
      id: "measure-lines",
      type: "line",
      source: "geojson",
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": "#000",
        "line-width": 2.5,
      },
      filter: ["in", "$type", "LineString"],
    });
  }
  clearDistanceMeasurer() {
    this.geojson = {
      type: "FeatureCollection",
      features: [],
    };
    this.map.getCanvas().style.cursor = "";
    this.map.getSource("geojson").setData(this.geojson);

    const distanceContainer = document.getElementById(this.domElementId);
    distanceContainer.innerHTML = "";
  }
}

export default { tapeMeasure: new TapeMeasure() };
