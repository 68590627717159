<template>
  <div class="diaporama_map">
    <v-carousel
      v-model="indexPhoto"
      hide-delimiter-background
      show-arrows-on-hover
      height="100%"
    >
      <v-carousel-item v-for="(item, i) in items" :key="i" height="100%">
        <v-img v-if="item.loaded" contain :src="item.src" height="100%"></v-img
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

export default {
  name: "diaporamaMap",
  props: {
    map: {
      type: Object
    },
    photosGeoJson: {
      type: Object
    },
    isOpen: {
      type: Boolean
    },
    decoration: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: [],
      avatar: null,
      markers: [],
      indexPhoto: 0,
      shouldReload: true
    };
  },
  methods: {
    async getImages(geojson) {
      this.items = [];
      let index = 0;
      for (const el of geojson.features) {
        if (index < 4 || index >= geojson.features.length - 3) {
          const document = await this.$api.documents.download(
            el.properties.path
          );
          this.$set(this.items, index, { src: "", loaded: false });
          const url = window.URL.createObjectURL(new Blob([document]));
          this.items[index].src = url;
          this.items[index].loaded = true;
        } else this.$set(this.items, index, { src: "", loaded: false });
        index++;
      }
    },
    moveAvatar(coords) {
      if (this.avatar) {
        this.avatar.remove();
        this.avatar = null;
      }
      const icon = document.createElement("i");
      icon.className = "large mdi mdi-truck avatar";
      icon.style.fontSize = "xx-large";

      // Add markers to the map.
      this.avatar = new mapboxgl.Marker(icon).setLngLat(coords).addTo(this.map);
      this.map.jumpTo({
        center: [coords[0], coords[1]],
        zoom: 16,
        pitch: 60
      });
    },
    load() {
      this.getImages(this.photosGeoJson).then(() => {
        this.indexPhoto = 0;
        if (this.decoration) {
          for (const marker of this.photosGeoJson.features) {
            const icon = document.createElement("i");
            /*icon.className = "large mdi mdi-camera-marker-outline";
            icon.style.fontSize = "x-large";
            icon.style.color = "grey";*/

            // Add markers to the map.
            this.markers.push(
              new mapboxgl.Marker(icon)
                .setLngLat(marker.geometry.coordinates)
                .addTo(this.map)
            );
          }
        }
      });
    },
    clearDecorations() {
      for (const marker of this.markers) {
        marker.remove();
      }
      this.markers = [];
      if (this.avatar) this.avatar.remove();
    }
  },
  watch: {
    photosGeoJson() {
      if (this.isOpen) {
        this.clearDecorations();
        this.load();
      }
    },
    isOpen: {
      handler(newVal) {
        if (newVal) {
          this.load();
          if (this.decoration) {
            this.moveAvatar(
              this.photosGeoJson.features[this.indexPhoto].geometry.coordinates
            );
          }
        } else {
          if (this.decoration) {
            this.clearDecorations();
          }
        }
      },
      deep: false
    },
    async indexPhoto(newVal) {
      if (this.isOpen && newVal && this.decoration)
        this.moveAvatar(
          this.photosGeoJson.features[newVal].geometry.coordinates
        );
      let startToPreload = newVal - 2 >= 0 ? newVal - 2 : 0;
      let endToPreload =
        newVal + 4 < this.items.length ? newVal + 4 : this.items.length - 1;
      for (let i = startToPreload; i <= endToPreload; i++) {
        if (!this.items[i].loaded) {
          let response = await this.$axios.get(
            "files/" + this.photosGeoJson.features[i].properties.path,
            {
              responseType: "blob",
              showLoader: !this.items[newVal].loaded
            }
          );
          const url = window.URL.createObjectURL(new Blob([response.data]));
          this.items[i].src = url;
          this.items[i].loaded = true;
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";
.diaporama_map {
  background-color: #f1f1f1;
  height: 100%;
  display: flex;
  align-items: center;
}
.avatar {
  color: $brown;
}
.mdi-circle {
  color: #412420 !important;
}
.v-btn--active {
  color: #412420 !important;
  background-color: rgba(65, 36, 36, 0.3) !important;
}
</style>
