<template>
  <div class="MapFilters">
    <v-card
      v-for="legend in legendsDisplayed"
      :key="legend.id"
      elevation="2"
      class="white mb-2"
    >
      <v-card-title>
        <h3>{{ $cap(legend.name) }}</h3>
      </v-card-title>
      <v-card-text>
        <v-checkbox
          class="mt-0 miniCheckbox"
          color="darkgrey"
          off-icon="mdi-checkbox-blank-outline"
          on-icon="mdi-checkbox-marked"
          dense
          v-for="layerLegends in legend.legends"
          :key="layerLegends.id"
          v-model="layerLegends.selected"
        >
          <template v-slot:label>
            <div class="labelBox">
              <div :style="{ background: layerLegends.color }"></div>
              <span> {{ layerLegends.label }}</span>
            </div>
          </template>
        </v-checkbox>
        <div class="reset_button_container">
          <medium-button-slot @click="resetFilter(legend.id)"
            >Réinitialiser</medium-button-slot
          >
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "MapFilters",
  props: {
    layersLegend: {
      required: true,
      type: Array,
    },
    selectedLayers: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    filters: [],
    id: "placeholder",
  }),
  mounted() {
    this.setFilters(this.filters);
  },
  watch: {
    layersLegend: {
      handler: function (newVal) {
        if (this.filters.length === 0) {
          this.filters = [...newVal];
        } else {
          let newFilters = [];
          newVal.forEach((el) => {
            let filtersFilteredByEl = this.filters.filter((filter) => {
              filter.id === el.id;
            });
            if (filtersFilteredByEl.length === 0) newFilters.push(el);
            else newFilters.push(filtersFilteredByEl[0]);
          });
          this.filters = [...newFilters];
        }
        this.filters.forEach((filter, indexFilter) => {
          filter.legends.forEach((legend, indexLegend) => {
            this.$set(
              this.filters[indexFilter].legends[indexLegend],
              "selected",
              true
            );
          });
        });
      },
      deep: false,
    },
    filters: {
      handler: function (newVal) {
        this.setFilters(newVal);
      },
      deep: true,
    },
  },
  methods: {
    resetFilter(legendId) {
      let layerToReset = this.layersLegend.filter((ll) => ll.id == legendId)[0];
      layerToReset.legends = layerToReset.legends.map((leg) => {
        leg.selected = true;
        return leg;
      });
    },
    setFilters(newVal) {
      console.log('Map.filtre.vuew::setFilters::filterChanged', newVal);
      this.$emit("filterChanged", newVal);
    },
  },
  computed: {
    legendsDisplayed() {
      return this.layersLegend.filter(
        (ll) => this.selectedLayers.filter((sl) => sl == ll.id).length > 0
      );
    },
  },
};
</script>

<style lang="scss">
.MapFilters {
  font-family: Roboto, sans-serif;
  position: absolute !important;
  top: 10px !important;
  right: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 2;

  .miniCheckbox i {
    transform: scale(0.8) !important;
  }

  .v-card__title {
    padding-bottom: 5px;
    h3 {
      font-size: 20px;
      justify-content: center;
      width: 100%;
    }
  }

  .v-messages {
    display: none !important;
  }

  .labelBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      font-weight: normal;
      text-transform: none;
      font-size: 12px;
      letter-spacing: 0.4px;
      color: rgba(0, 0, 0, 0.6);
    }
    div {
      width: 12px;
      height: 12px;
      border-radius: 12px;
      margin-right: 2px;
    }
  }
  .reset_button_container {
    padding-top: 5px;
    display: flex;
    justify-content: center;
  }
}
</style>
