<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        max-width="40"
        height="40"
        :disabled="disabledStat"
        color="#f1f1f1"
        v-bind="attrs"
        v-on="on"
        @click="clickEvent"
      >
        <v-icon size="25" color="secondary">
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "MapBtn",
  props: ["clickEvent", "icon", "tooltip", "disabledStat"]
};
</script>

<style lang="scss" scoped>
button {
  min-width: 40px !important;
  margin: 5px !important;
}
</style>
